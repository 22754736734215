var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-overlay',{staticClass:"w-100",attrs:{"show":_vm.loading,"opacity":"0.95","rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"display-4"},[_vm._v(" \"Give me a firm spot on which to stand, and I shall move the earth\" "),_c('br'),_c('span',{staticClass:"h3"},[_vm._v("— Archimedes of Syracuse")])]),_c('div',{staticClass:"d-inline-flex"},[_c('b-spinner',{staticClass:"my-1",attrs:{"variant":"primary"}})],1)])]},proxy:true}])},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo d-flex align-items-center"},[_c('b-img',{staticStyle:{"height":"40px"},attrs:{"src":require('@/assets/images/logo/starworks.png')}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"6"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"src":_vm.imgUrl,"alt":"Login V2","fluid":""}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"6"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"lg":"6","md":"6","sm":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-img',{staticStyle:{"width":"60%"},attrs:{"src":_vm.logos[_vm.currentdomain] || _vm.logos.default}})],1),_c('b-card-text',{staticClass:"mt-4 mb-2 text-center font-weight-bolder"},[_vm._v(" Please continue to your account and start the adventure 🚀 ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleFormExecution()}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"login-email","autocomplete":_vm.isEmailInput ? 'username' : '',"disabled":_vm.loadingUserStatus,"state":errors.length > 0 ? false:null,"name":"login-email","placeholder":"john@example.com"},on:{"update":function($event){_vm.formState = 'EMAIL_INPUT'},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.checkUserStatus($event)}},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}})],1),_c('b-progress',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingUserStatus),expression:"loadingUserStatus"}],staticClass:"mt-50",attrs:{"animated":"","height":"0.5rem","value":"100"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.isSignUp)?_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-fullname"}},[_vm._v("Full Name")])]),_c('validation-provider',{attrs:{"name":"FullName","rules":"required","vid":"fullname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-fullname","state":errors.length > 0 ? false:null,"name":"login-fullname","placeholder":"Full Name","type":"text"},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSignIn || _vm.isSignUp),expression:"isSignIn || isSignUp"}]},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v(_vm._s(_vm.passwordPlaceholder))])]),_c('validation-provider',{attrs:{"autocomplete":_vm.isSignIn ? 'current-password' : 'new-password',"rules":!_vm.isEmailInput ? 'required' : '',"name":"Password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","placeholder":_vm.passwordPlaceholder,"state":errors.length > 0 ? false : null,"type":_vm.passwordFieldTypeAndIcon.type,"name":"login-password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordFieldTypeAndIcon.icon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.isSignUp)?_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-confirmPassword"}},[_vm._v("Confirm Password")])]),_c('validation-provider',{attrs:{"name":"confirmPassword","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-confirmPassword","state":errors.length > 0 ? false:null,"autocomplete":"new-password","name":"login-confirmPassword","placeholder":"Confirm Password","type":"password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.resetCodeSent)?_c('b-form-group',{attrs:{"label":"Confirmation Code","label-for":"confirmation-code"}},[_c('validation-provider',{attrs:{"name":"Confirmation Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"confirmation-code","state":errors.length > 0 ? false:null,"name":"confirmation-code","placeholder":"012345"},model:{value:(_vm.resetCode),callback:function ($$v) {_vm.resetCode=$$v},expression:"resetCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])),_c('br')]),(_vm.showResendCode)?_c('small',[_vm._v("Haven't received the verification code? "),_c('b-link',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.resendVerificationCode()}}},[_vm._v("Resend Code ")])],1):_vm._e(),(!_vm.showResendCode)?_c('small',{staticClass:"text-muted"},[_vm._v("You can request new code after 30 seconds.")]):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.isEmailInput)?_c('b-button',{attrs:{"disabled":invalid || _vm.loading,"block":"","type":"button","variant":"primary"},on:{"click":_vm.checkUserStatus}},[_vm._v(" Next ")]):_vm._e(),(_vm.isSignIn || _vm.isSignUp)?_c('b-button',{attrs:{"disabled":invalid || _vm.loading || (_vm.password !== _vm.confirmPassword && _vm.isSignUp),"block":"","type":"submit","variant":"primary"}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.isSignIn ? 'Sign in' : 'Sign up')+" ")],1):_vm._e()],1)]}}])}),_c('b-card-text',{staticClass:"text-center mt-2",staticStyle:{"margin-top":"-10px"}},[_c('span',[_vm._v("Forgot Password? ")]),_c('b-link',{attrs:{"to":{name:'auth-forgot-password', query: _vm.$route.query}}},[_c('span',[_vm._v(" Reset it Here")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }