<template>
  <div class="auth-wrapper auth-v2">
    <b-overlay
      :show="loading"
      class="w-100"
      opacity="0.95"
      rounded="sm"
    >
      <b-row class="auth-inner m-0">

        <!-- Brand logo-->
        <b-link
          class="brand-logo d-flex align-items-center"
        >
          <b-img
            :src="require('@/assets/images/logo/starworks.png')"
            style="height: 40px;"
          />
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col
          class="d-none d-lg-flex align-items-center p-5"
          lg="6"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              :src="imgUrl"
              alt="Login V2"
              fluid
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
          lg="6"
        >
          <b-col
            class="px-xl-2 mx-auto"
            lg="6"
            md="6"
            sm="6"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="logos[currentdomain] || logos.default"
                style="width:60%"
              />
            </div>
            <b-card-text
              class="mt-4 mb-2 text-center font-weight-bolder"
            >
              Please continue to your account and start the adventure 🚀
            </b-card-text>

            <validation-observer
              ref="loginForm"
              v-slot="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="handleFormExecution()"
              >
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="login-email"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                    vid="email"
                  >
                    <b-input-group>
                      <b-form-input
                        id="login-email"
                        v-model="userEmail"
                        :autocomplete="isEmailInput ? 'username' : ''"
                        :disabled="loadingUserStatus"
                        :state="errors.length > 0 ? false:null"
                        name="login-email"
                        placeholder="john@example.com"
                        @update="formState = 'EMAIL_INPUT'"
                        @keyup.enter="checkUserStatus"
                      />
                    </b-input-group>
                    <b-progress
                      v-show="loadingUserStatus"
                      animated
                      class="mt-50"
                      height="0.5rem"
                      value="100"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group v-if="isSignUp">
                  <div class="d-flex justify-content-between">
                    <label for="login-fullname">Full Name</label>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="FullName"
                    rules="required"
                    vid="fullname"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid':null"
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="login-fullname"
                        v-model="fullname"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="login-fullname"
                        placeholder="Full Name"
                        type="text"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!--  password  -->
                <b-form-group v-show="isSignIn || isSignUp">
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ passwordPlaceholder }}</label>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    :autocomplete="isSignIn ? 'current-password' : 'new-password'"
                    :rules="!isEmailInput ? 'required' : ''"
                    name="Password"
                    vid="password"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid':null"
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :placeholder="passwordPlaceholder"
                        :state="errors.length > 0 ? false : null"
                        :type="passwordFieldTypeAndIcon.type"
                        class="form-control-merge"
                        name="login-password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordFieldTypeAndIcon.icon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>

                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="isSignUp">
                  <div class="d-flex justify-content-between">
                    <label for="login-confirmPassword">Confirm Password</label>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="confirmPassword"
                    rules="required"
                    vid="password"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid':null"
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="login-confirmPassword"
                        v-model="confirmPassword"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="new-password"
                        class="form-control-merge"
                        name="login-confirmPassword"
                        placeholder="Confirm Password"
                        type="password"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="resetCodeSent"
                  label="Confirmation Code"
                  label-for="confirmation-code"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Confirmation Code"
                    rules="required"
                  >

                    <b-form-input
                      id="confirmation-code"
                      v-model="resetCode"
                      :state="errors.length > 0 ? false:null"
                      name="confirmation-code"
                      placeholder="012345"
                    />
                    <small class="text-danger">{{ errors[0] }}<br></small>
                    <small v-if="showResendCode">Haven't received the verification code?
                      <b-link
                        variant="link"
                        @click="resendVerificationCode()"
                      >Resend Code
                      </b-link>
                    </small>
                    <small
                      v-if="!showResendCode"
                      class="text-muted"
                    >You can request new code after 30 seconds.</small>
                  </validation-provider>
                </b-form-group>

                <!-- Next button -->
                <b-button
                  v-if="isEmailInput"
                  :disabled="invalid || loading"
                  block
                  type="button"
                  variant="primary"
                  @click="checkUserStatus"
                >
                  Next
                </b-button>
                <!-- Sign in / Sign up button -->
                <b-button
                  v-if="isSignIn || isSignUp"
                  :disabled="invalid || loading || (password !== confirmPassword && isSignUp)"
                  block
                  type="submit"
                  variant="primary"
                >
                  <b-spinner
                    v-if="loading"
                    small
                  />
                  {{ isSignIn ? 'Sign in' : 'Sign up' }}
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text
              class="text-center mt-2"
              style="margin-top:-10px"
            >
              <span>Forgot Password? </span>
              <b-link :to="{name:'auth-forgot-password', query: $route.query}">
                <span>&nbsp;Reset it Here</span>
              </b-link>
            </b-card-text>

          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
      <template #overlay>
        <div class="text-center">
          <p class="display-4">
            "Give me a firm spot on which to stand, and I shall move the earth"
            <br>
            <span class="h3">— Archimedes of Syracuse</span>
          </p>

          <div class="d-inline-flex">
            <b-spinner
              class="my-1"
              variant="primary"
            />
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCardText,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
  BProgress,
  BRow,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import gql from 'graphql-tag'
import { Auth } from 'aws-amplify'
import GET_USER_AND_ASSOC_ORGS_QUERY from '@/graphql/queries/getUserAndAssocOrgDetails'
import { roleDefinitions, roleAbilities } from '@/constants/acl-roles'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BProgress,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,
    BCardText,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      currentdomain: '',
      loading: false,
      loadingUserStatus: false,
      showResendCode: false,
      resetCodeSent: false,
      status: '',
      password: '',
      confirmPassword: '',
      userEmail: '',
      fullname: '',
      resetCode: '',
      logos: {
        impunjab: require('@/assets/images/logo/imp.png'),
        ginserv: require('@/assets/images/logo/ginserv.png'),
        aghub: require('@/assets/images/logo/aghub.png'),
        actionplaniitb: require('@/assets/images/logo/abhyuday.png'),
        'aic-prestige': require('@/assets/images/logo/aic-prestige.png'),
        default: require('@/assets/images/logo/starworks.jpg'),
      },
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      randomFact: '',
      // validation rules
      required,
      email,
      userInfo: [],
      pageState: 0,
      formState: 'EMAIL_INPUT',
    }
  },
  computed: {
    isSignIn() {
      return this.formState === 'SIGN_IN'
    },
    isSignUp() {
      return this.formState === 'SIGN_UP'
    },
    isEmailInput() {
      return this.formState === 'EMAIL_INPUT'
    },
    passwordPlaceholder() {
      return this.formState === 'SIGN_IN' ? 'Password' : 'Create Password'
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordFieldTypeAndIcon() {
      return {
        type: this.passwordFieldType === 'password' ? 'password' : 'text',
        icon: this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon',
      }
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.getHost()
    this.verifyAuthentication()
  },
  methods: {
    getHost() {
      this.currentdomain = window.location.host.split('.')[0].toString()
    },

    async verifyAuthentication() {
      try {
        const user = await Auth.currentAuthenticatedUser()
        if (user) this.handlePostLogin(user)
      } catch (error) {
        this.showAlert('Welcome to Starworks Prime', 'primary', 'Please login to continue')
      }
    },

    async handleFormSubmission(formAction) {
      if (await this.$refs.loginForm.validate()) {
        this.loading = true
        try {
          await formAction()
        } catch (error) {
          this.$refs.loginForm.setErrors({ email: error.message, password: ' ' })
        } finally {
          this.loading = false
        }
      }
    },

    async handleFormExecution() {
      if (this.isEmailInput) {
        await this.processForm(this.checkUserStatus)
      } else if (this.isSignIn) {
        await this.processForm(this.login)
      } else {
        await this.processForm(this.register)
      }
    },

    async processForm(action) {
      if (!await this.$refs.loginForm.validate()) return
      this.loading = true
      try {
        await action()
      } catch (error) {
        this.$refs.loginForm.setErrors({ email: error.message, password: ' ' })
      } finally {
        this.loading = false
      }
    },

    async checkUserStatus() {
      const isEmailValid = await this.$refs.loginForm.validate()
      if (!isEmailValid) return

      this.loadingUserStatus = true
      try {
        const { data } = await this.$apollo.query({
          query: gql`
            query checkUserStatus($email: String!) {
              checkUserStatus(email: $email)
              {
                 success
              }
            }
          `,
          variables: { email: this.userEmail },
        })
        if (data.checkUserStatus.success) this.formState = 'SIGN_IN'
        else this.formState = 'SIGN_UP'
      } catch (error) {
        this.formState = 'SIGN_UP'
      } finally {
        this.loadingUserStatus = false
      }
    },

    async login() {
      try {
        const response = await Auth.signIn(this.userEmail, this.password)
        this.handlePostLogin(response)
      } catch (error) {
        this.$refs.loginForm.setErrors({ email: '', password: error.message })
      }
    },

    async register() {
      if (this.resetCodeSent) {
        await Auth.confirmSignUp(this.userEmail, this.resetCode)
          .then(data => {
            if (data === 'SUCCESS') {
              this.showAlert('Account created successfully', 'success', 'You can now login')
              return this.login() // Reuse login logic after confirmation
            }
            return this.showAlert('Error', 'danger', 'Please enter the correct confirmation code')
          }).catch(err => {
            this.showAlert('Error', 'danger', err.message)
          })
      } else {
        try {
          await Auth.signUp({
            username: this.userEmail,
            password: this.password,
            attributes: { name: this.fullname },
          })
          this.resetCodeSent = true
          this.showResendCode = false
          setTimeout(() => {
            this.showResendCode = true
          }, 30000)
          this.showAlert('Confirmation code sent', 'success')
        } catch (err) {
          this.showAlert('Error', 'danger', err.message)
        }
      }
    },

    resendVerificationCode() {
      this.loading = true
      this.showResendCode = false
      setTimeout(() => {
        this.showResendCode = true
      }, 30000)
      return Auth.resendSignUp(this.userEmail)
        .then(() => {
          this.loading = false
          this.showAlert('The confirmation code has been sent again', 'success', 'Please enter the code to verify your account')
        })
        .catch(err => {
          this.loading = false
          this.showAlert('Error', 'danger', err.message)
        })
    },

    handlePostLogin(response) {
      if (!response) return

      const authUserData = {
        fullName: response.attributes.name,
        userEmail: response.attributes.email,
      }

      const subdomain = this.getIncubatorFromSubdomain() || '.*'
      const loadIncubatorsWithoutDomain = !this.getIncubatorFromSubdomain()

      this.$apollo.query({
        query: GET_USER_AND_ASSOC_ORGS_QUERY,
        variables: {
          email: authUserData.userEmail,
          subdomain,
          loadIncubatorsWithoutDomain,
        },
      })
        .then(({ data }) => {
          this.updateStoreAndNavigate(authUserData, data.users_customuser[0])
        })
    },

    async updateStoreAndNavigate(authUserData, data) {
      const userData = { ...authUserData }

      const { users_associationtables } = data
      userData.associatedOrgDetails = users_associationtables.filter(org => org.role && (org.incubator_id || org.organization_id))

      const roles = userData.associatedOrgDetails.filter(({ role }) => role).map(({ role }) => roleDefinitions[role.toLowerCase()].role)
      const roleSet = new Set(roles)

      switch (true) {
        case roleSet.has('incubator_admin'):
          userData.selectedOrg = userData.associatedOrgDetails.find(({ role }) => roleDefinitions[role.toLowerCase()].role === 'incubator_admin')
          break
        case roleSet.has('incubator_mentor'):
          userData.selectedOrg = userData.associatedOrgDetails.find(({ role }) => roleDefinitions[role.toLowerCase()].role === 'incubator_mentor')
          break
        case roleSet.has('startup_admin'):
          userData.selectedOrg = userData.associatedOrgDetails.find(({ role }) => roleDefinitions[role.toLowerCase()].role === 'startup_admin')
          break
        default:
          userData.role = 'user'
          userData.ability = [
            { action: 'read', subject: 'Auth' },
            { action: 'read', subject: 'Dashboard' },
            { action: 'manage', subject: 'User' },
          ]
          userData.selectedOrg = {}
      }

      userData.role = roleDefinitions[userData.selectedOrg?.role]?.role || 'user'
      userData.ability = roleAbilities[userData.role.toLowerCase()]

      userData.id = data.id
      userData.timezone = JSON.parse(data.timezone)
      userData.user_id = data.user_id || ''

      store.dispatch('user/setUserDetails', userData)
      this.loading = false
      this.showAlert(`Welcome ${userData.fullName || ''}`, 'success', `You have successfully logged in as ${userData.role}. Now you can start to explore!`)

      this.$ability.update(userData.ability)
      await store.dispatch('organization/fetchAssociatedOrgsBySelectedOrgId')

      if (localStorage.getItem('redirectAfterLogin')) {
        const redirectURL = localStorage.getItem('redirectAfterLogin')
        localStorage.removeItem('redirectAfterLogin')
        this.$router.push(redirectURL)
      } else if (this.$route.query.redirect) {
        this.$router.push(decodeURIComponent(this.$route.query.redirect))
      } else {
        this.$router.push({ name: 'dashboard' })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
