import gql from 'graphql-tag'

const GET_USER_AND_ASSOC_ORGS_QUERY = gql`
    query userAndAssocOrgs($email: String!, $subdomain: String!, $loadIncubatorsWithoutDomain: Boolean = true) {
        users_customuser(where: { email: { _eq: $email } }) {
            id
            first_name
            email
            timezone
            user_id
            users_associationtables(where: {
                _or: [
                    { usersOrganizationtableByIncubator: { domain: { _regex: $subdomain } } },
                    { usersOrganizationtableByIncubator: { domain: { _is_null: $loadIncubatorsWithoutDomain } } },
                    { incubator_id: { _is_null: true } } # Fetch associated startups
                ]
            }) {
                id
                organization_id
                incubator_id
                role
                designation
                users_organizationtable {
                    id
                    title
                    type
                    domain
                }
                usersOrganizationtableByIncubator {
                    id
                    title
                    type
                    domain
                }
            }
        }
    }
`

export default GET_USER_AND_ASSOC_ORGS_QUERY
